import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Banner from '../components/Banner'
import TeamMemberCard from '../components/TeamMemberCard'

const AboutPage = ({ location, data }) => {
  const pageData = data.prismicAbout.data
  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <Banner title={pageData.title} image={pageData.hero_image} hero />

      <div className="px-ogs">
        <div className="wrapper">
          <div className="border-b border-grey-lighter mb-15 lg:mb-28">
            <Fade ssrReveal delay={250} duration={2250}>
              <div className="lg:flex lg:-ml-gs pb-15 lg:pb-28">
                <div className="lg:w-1/2 lg:pl-gs mb-12">
                  {pageData.lead_heading && (
                    <div className="lg:w-2/3">
                      <h2
                        className="text-lg font-bold tracking-wide whitespace-pre-line leading-9"
                        dangerouslySetInnerHTML={{
                          __html: pageData.lead_heading,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="lg:w-1/2 lg:pl-gs">
                  {pageData.lead_body.html && (
                    <div
                      className="rte"
                      dangerouslySetInnerHTML={{
                        __html: pageData.lead_body.html,
                      }}
                    />
                  )}
                </div>
              </div>
            </Fade>
          </div>

          <Fade ssrReveal delay={250} duration={2250}>
            <div className="lg:flex lg:-ml-gs pb-15 lg:pb-28">
              <div className="lg:w-1/2 lg:pl-gs mb-12">
                {pageData.values_heading && (
                  <div className="lg:w-2/3">
                    <h4 className="text-lg tracking-wide">
                      {pageData.values_heading}
                    </h4>
                  </div>
                )}
              </div>
              <div className="lg:w-1/2 lg:pl-gs">
                {pageData.values_list.length && (
                  <ul className="md:flex md:flex-wrap md:-ml-gs -mb-10">
                    {pageData.values_list.map((item, index) => {
                      return (
                        <li key={index} className="md:w-1/2 md:pl-gs pb-10">
                          {item.value_heading && (
                            <h3 className="text-lg uppercase mb-5 font-bold tracking-widest">
                              {item.value_heading}
                            </h3>
                          )}
                          {item.value_body.html && (
                            <div
                              className="rte"
                              dangerouslySetInnerHTML={{
                                __html: item.value_body.html,
                              }}
                            />
                          )}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            </div>
          </Fade>

          {pageData.values_image.localFile && (
            <Fade ssrReveal delay={250} duration={2250}>
              <div className="w-full pb-15 lg:pb-28">
                <Img
                  fluid={pageData.values_image.localFile.childImageSharp.fluid}
                  alt={pageData.values_image.alt || pageData.values_heading}
                />
              </div>
            </Fade>
          )}

          {pageData.team_members_list.length > 0 && (
            <Fade ssrReveal delay={250} duration={2250}>
              <div className="pb-15 lg:pb-44">
                <div className="text-center pb-15 lg:pb-30">
                  <h3 className="text-xl lg:text-2xl font-condensed uppercase tracking-wider">
                    Management Team
                  </h3>
                </div>
                <div className="md:flex md:flex-wrap justify-center md:-ml-gs -mb-12">
                  {pageData.team_members_list
                    .filter(item => item.team_member_name !== null)
                    .map((item, index) => {
                      return <TeamMemberCard item={item} key={index} />
                    })}
                </div>
              </div>
            </Fade>
          )}
        </div>
      </div>

      <Banner
        title={pageData.cta_heading}
        image={pageData.cta_image}
        link={pageData.cta_link}
        linkText={pageData.cta_link_text}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default AboutPage

export const query = graphql`
  query {
    prismicAbout {
      data {
        meta_title
        meta_description
        cta_heading
        cta_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        cta_link_text
        cta_link {
          url
        }
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        lead_body {
          html
        }
        lead_heading
        meta_description
        meta_title
        team_heading
        team_members_list {
          team_member_email_address
          team_member_name
          team_member_phone_number
          team_member_role
          team_member_image {
            alt
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        title
        values_heading
        values_list {
          value_body {
            html
          }
          value_heading
        }
        values_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title
      }
    }
  }
`
