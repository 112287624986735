import React from 'react'
import Img from 'gatsby-image'

const TeamMemberCard = ({ item }) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/4 md:pl-gs mb-12">
      {item.team_member_image.localFile && (
        <div className="mb-10">
          <Img
            className="absolute inset-0 object-cover"
            fluid={item.team_member_image.localFile.childImageSharp.fluid}
            alt={item.team_member_image.alt || item.team_member_name}
          />
        </div>
      )}
      {item.team_member_name && (
        <h4 className="text-lg uppercase mb-3 font-bold tracking-widest">
          {item.team_member_name}
        </h4>
      )}
      {item.team_member_role && (
        <div className="mb-6">{item.team_member_role}</div>
      )}
      {item.team_member_phone_number && (
        <div>
          <a href={`tel:${item.team_member_phone_number.replace(/\s/g, '')}`}>
            {item.team_member_phone_number}
          </a>
        </div>
      )}
      {item.team_member_email_address && (
        <div>
          <a
            className="underline"
            href={`mailto:${item.team_member_email_address}`}
          >
            {item.team_member_email_address}
          </a>
        </div>
      )}
    </div>
  )
}

export default TeamMemberCard
